.episode-p-h-1 {
  padding: 120px 0px 0px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.episode-p-h-2 {
  width: 80%;
}
.episode-p-h-3 > h1 {
  font-size: 28px;
  text-transform: uppercase;

  width: fit-content;
}
.episode-iframe {
  padding: 41px 0px 85px 0px;
}
.iframe-audio-p1 {
  padding: 20px 0px;
  color: black;
}
.iframe-audio-p1 > p {
  font-size: 18px;
}
.iframe-audio-p1 > h1 {
  font-size: 20px;
  font-weight: 400;
  color: black;
}
.text-area1 {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  resize: none;
}

label[for="message"] {
  font-size: 16px;
}
.text-area1:focus,
input:focus {
  outline: none;
}
.text-area1[disabled] {
  background-color: #eee;
}
.input-submit-episode {
  display: flex;
  justify-content: flex-end;
}
.form-text-area-episode {
  border: 1px solid #cbccce;
  padding: 40px;
  background: white;
  border-radius: 7px;
}
.publish-btn-episodes:hover {
  background: #bc0000;

  border-radius: 20px;
  padding: 9px 14px;
  border: none;
  color: white;
}
.publish-btn-episodes{
    background: rgb(178 174 174 / 47%);
  border-radius: 20px;
  padding: 9px 14px;
  border: none;
  color: black;
}

.user-name-episode {
    display: flex;
    gap: 10px;
    padding: 20px 0px;
    width: fit-content;
    align-items: center;
    cursor: pointer;
}
.user-name-episode > h1 {
  font-size: 15px;
  font-weight: 500;
}
.user-name-episode > svg{
  font-size: 25px;
}
.user-name-episode:hover>h1{
    color: #bc0000;
 

}
@media only screen and (max-width: 380px) {

.form-text-area-episode {
 
    padding: 14px;

}
}
.icon-download{
  display: none;
}

/* swiper */




/*  */
.swiper-border-1>h1{
cursor: pointer;
  font-size: 15px;
  
}
.swiper-border-1>h1:hover{
  color: #bc0000;


}
.swiper-border-1>p{
  font-size: 12px;
}

.swiper-border-1{
  border: 1px solid;
  display: flex;
  gap: 1rem;
  padding: 42px 7px;
  flex-direction: column;

  background: white;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  align-items: center;

}
/*  */
.prev {
  background-color: #4CAF50;
  color: white;
  padding: 16px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
}

.next {
  background-color: #4CAF50;
  color: white;
  padding: 16px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
}
.tool-b {
  --plz-color-black-2: #212529;
  --plz-color-white: white;
  --plz-border-radius-default: 0.25rem;
  --scale: 0;
  --arrow: 5px;
  
  display: flex;
  padding:10px;
  padding: 10px;
  justify-content: flex-end;
  display: flex;
  gap: 2px;
}


.tooltip {
  position: relative;
  font-size: 20px;
  /* padding: 0.5em; */
  border-radius: 0.2em;
  box-sizing: border-box;
}

.tooltip::before,
.tooltip::after {
  position: absolute;
  transform: translateX(-50%) translateY(var(--translate-y, 0))     scale(var(--scale));
  transition: 200ms transform;
  transform-origin: bottom center;
  top: -0.25rem;
  left: 50%;
}

.tooltip::before {
  --translate-y: calc(-100% - var(--arrow)); /* Para controlar la flecha del tooltip */
  background-color: var(--plz-color-black-2);
  color: var(--plz-color-white);
  text-align: center;
  border-radius: var(--plz-border-radius-default);
  content: attr(data-tool);
  padding: 0.5rem;
  /* Esto hará que el texto se distribuya mejor dentro del espacio */
  width: max-content;
  font-size: 12px;

  /* max-width: 100%; */
}

.tooltip:hover::before,
.tooltip:hover::after {
  --scale: 1;
}

.tooltip::after {
  --translate-y: calc(-1 * var(--arrow));
  transform-origin: top center;
  content: '';
  border: var(--arrow) solid transparent;
  border-top-color: var(--plz-color-black-2);
  border-top-width: 5px;
}
.previous-btn{

  padding: 7px 20px;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;

  display: flex;
  align-items: center;
  background-color: #bc0000;
  color: white;
}
.next-btn{

  padding: 7px 20px;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;

  display: flex;
  align-items: center;
  background-color: #bc0000;
  color: white;
}