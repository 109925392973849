.plan-bg{
    background-image: url("../../Img/20210204_a547.jpg");
    background-position: center;
    background-size: cover;
    min-height: 618px;
}

  .tb-plan{
width: 90%;
  }
  .tb-plan1{
    width: 100%;
    display: flex;
    justify-content: center;
    
    padding: 100px 0px 0px 0px;
  }

  .plan-h2{

      font-weight: 500;
      font-size: 20px;
    }

      
      
      
      
      /*  */
      .tb-1{
        border: 1px solid #ccc;
        border-collapse: collapse;
        margin: 0;
        padding: 0;
        width: 100%;
        table-layout: fixed;
      }
      
      .tb-cap{
        font-size: 1.5em;
        margin: .5em 0 .75em;
      }
      
     .tr-plan{
        background-color: #f8f8f8;
        border: 1px solid #ddd;
        padding: .35em;
      }
      
      .tb-cap>th,
        td{
            padding: .625em;
            text-align: center;
        }
        .th-plan{

            padding: .625em;
            text-align: center;
        }
        
        .th-plan{
        font-size: .85em;
        letter-spacing: .1em;
        text-transform: uppercase;
      }
      
      @media screen and (max-width: 900px) {
        .tb-1{
          border: 0;
        }
      
        .tb-cap{
          font-size: 1.3em;
        }
        
        .tb-1>thead {
          border: none;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }
        
      .tr-plan{
          border-bottom: 1px solid #ddd;
          display: block;
          margin-bottom: .625em;
        }
        
        .td-plan{
          border-bottom: 1px solid #ddd;
          display: block;
          font-size: .8em;
          text-align: right;
        }
        
        .td-plan::before {
          /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
          content: attr(data-label);
          float: left;
          font-weight: bold;
          text-transform: uppercase;
        }
        
        .td-plan:last-child {
          border-bottom: 0;
        }
      }
      .yes-1{
color:#32cd32;
          
        }
      
      
      
      
      
      
      
      
      
      
