.course-bg{
    background-image: url('../../Img/20210204_a547.jpg');
background-position: center;
background-size: cover;

}

.course-h1{
    padding: 20px 0px;
    color: #bc0000;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
}
.pdf-about-pages>img{
    width: 500px;

}
.pdf-about-pages{
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap: 2rem;
/* width: 40%; */
}
.pdf-logo{
    position: fixed;
    bottom: 0;
    right: 0;
    width: 52px;
    height: 52px px;

    margin: 16px;
}

.svg-pdf{

    font-size: 40px;
}

.flx-cor{
    flex-direction: column;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.course-h2{

    
    color: #bc0000;
    text-align: center;
    font-weight: 400;
    font-size: 15px;
}
.course-info{

    padding: 100px 0px 0px 0px;
}

/* download */
