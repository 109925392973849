.bg-hh{
background-image: url('./../../Img/20210204_a547.jpg');
background-position: center;
background-size: cover;

}
.main-div-h-d{
    display: flex;
}

.img-home-g{
    width: 450px;
    height: auto;
    object-fit: cover;
    border-radius: 15px;
}
.img-home-g1{
    width: 450px;
    height: auto;
    object-fit: cover;
    border-radius: 15px;
}
.img-home-g2{
    cursor: pointer;
    width: 340px;
    height: auto;
    object-fit: cover;
    border-radius: 15px;
}
.home-p-gg{
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    /* margin: 30px 0px; */
    flex-wrap: wrap;
    /* margin: 30px 0px; */
}
.log-2{

    display: flex;
    flex-direction: column;
    width: fit-content;
    align-items: center;
}
.home-logo{

    width: 200px;
}
.top-ii{

    margin-top: 40px;
}
.home-side-bar-data{
    display: flex;
    width: 100%;
}
.update-1>h1{

    font-size: 16px;
    /* position: relative; */
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    border: 1px solid transparent;
    color: #d41d1f;
    background-color: #fdf7f7;
border-color: #fca8a8;
cursor: pointer;
}
.data-div-h{
width: 100%;
display: flex;
justify-content: center;
    /* width: calc(100% - 96px); */
}
.sidebar-home-d{
    /* width: 96px; */
    /* position: fixed;
    z-index: 60; */
}
.data-container{
width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* align-items: center; */
    padding: 100px 0px;

}
@media only screen and (max-width:600px) {

    .data-container{
        
            /* align-items: center; */
            padding: 160px 0px;
        
        }
}

.h1-courses>h1{
    text-align: center;
    font-size: 22px;
}
.course-home-cards{
padding: 15px 0px;
}
.card-h-certifi>h1{
    text-align: center;

    font-size: 15px;
    padding: 10px 0px;
   
}
.card-flex-wrap{

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
}
.bodd-d{
     justify-content: center;
    align-items: center;
    /* background: #000; */
    display: flex;
    /* height: 100vh; */

  }
  
  .progress {
    background:black;
    justify-content: flex-start;
    border-radius: 100px;
    align-items: center;
    position: relative;
    padding: 0 5px;
    display: flex;
    height: 7px;
    width: 200px;
  }
  
  .progress-value {
    animation: load 3s normal forwards;
    box-shadow: 0 10px 40px -10px #fff;
    border-radius: 100px;
    background: #bc0000;
    height: 5px;
    width: 0;
    transform: translateX(-4px);
  }
  
  @keyframes load {
    0% { width: 0; }
    100% { width: 68%; }
  }
  .ww{
    width: 300px;
    border-radius: 15px ;
    cursor: pointer;
  }

  .card-head-course{
    gap: 2rem;
padding: 20px 0px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
    .percentage-course-com{
        color: #bc0000;
        font-size: 12px;
        font-weight: 700;
        text-align: center;
        padding: 10px 0px;
    }
    .view-btn-hom{

        font-family: "Roboto", Sans-serif;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        background-color: #D11E1E;
        box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 50%);
        border-radius: 4px 4px 4px 4px;
        padding: 15px 15px ;
        transition: 300ms;
    }
    .card-head-data-j{
        display: flex;
        
        flex-direction: column;
        align-items: center;
        /* gap: 1rem; */
    }
    


