.bg-formus {
  background-image: url("../../Img/20210204_a547.jpg");
  background-position: center;
  background-size: cover;
  min-height: 618px;
  /* height: 89vh; */
}
.flex-sid-forms{
    display: flex;
    width: 100%;
}
.forums-data{

     display: flex;
    justify-content: center;
    flex-direction: column;
    /* align-items: center; */
    /* padding: 7% 9%;; */
    width: 80%;
}
.forums-data1{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 114px 0px;
}
.search-input-forums{

    font-size: 14px;
    font-weight: 400;
white-space: nowrap;
height: 36px;
line-height: 36px;
overflow: hidden;
text-overflow: ellipsis;
min-width: 100%;
padding-right: 35px;
padding-left: 35px;

border-radius: 100px;
}
.Subscribe-btn-1{
    border: 1px solid;
    padding: 11px 20px;
    border-radius: 34px;
    color: black;
}
.Subscribe-btn-1:hover{
 background-color: #bc0000;
 color: white;

}
.New-discussion-btn1{
    border: 1px solid;
    padding: 11px 20px;
    border-radius: 34px;
    background-color: #bc0000;
    color: white;
}
.btn-ss{

    display: flex;
    gap: 10px;
    
}
.sub-sc{
    gap: 1rem;
align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 13px 8px;
    flex-wrap: wrap;
}
.border-jh{

    border: 1px solid #b8acac;
    margin: 17px 10px;
    border-radius: 5px;
}
.h-h1{
cursor: pointer;
    font-size: 16px;

}


.comment-1:hover .h-h1 {

    color: #bc0000;
}
.comm-gf{
  
    display: flex;
    gap: 1rem;
     flex-wrap: wrap;
}
.span-updates>span{
    font-size: 14px;
    display: flex;
    align-items: center;
     flex-wrap: wrap;


}
.comment-1{
padding: 10px 20px;
background-color: white;
transition: all 1s ease;
}
.comment-1:hover{

box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.span-updates{
display: flex;
gap: 10px;
padding: 10px 0px;
flex-wrap: wrap;
color: #a3a5a9;

}
.hov-span1:hover{
color: #bc0000;
cursor: pointer;
}
.all-h1{

    font-size: 20px;
    font-weight: 400;
}
.svg-user{

    font-size: 30px;
}
.svg-fill{
color: #a3a5a9;


    font-size: 25px;
}
.main-comments-div{

    display: flex;
    gap: 1rem;
    background: white;
    flex-direction: column;
}

/* modal */
.ReactModal__Content {
    padding: 0px !important;
    inset: 73px 317px !important;
    /* height: 70vh; */
    height: fit-content;
    margin-top: 70px;

}
@media only screen and (max-width: 1050px) {
    .ReactModal__Content {
        inset: 68px 107px !important;
        /* inset: 147px 70px !important; */
    }

}
.header-modal-s{
    display: flex;
    /* width: 100%; */
    justify-content: space-between;
    align-items: center;
    padding: 25px 10px;;
    /* flex-wrap: wrap; */
    gap: 5px;
}
.svg-close-modal>svg{
    cursor: pointer;
}
.user-dp-modal-reply>svg{
    font-size: 41px;
}
.search-input{
    width: 85%;
}
.search-input>input{
    width: 100%;
    padding: 10px;
    color: white;
    border: none;
    border-radius: 15px;
    background:#bc00004f;
}
.search-input>input:focus{
    border: none !important;
}
.svg-close-modal{
    width: 30px;
    font-size: 20px;
    color: black;
}
.user-dp-modal-reply{
width: 40px;
}
.search-input>input:focus{
    outline: none;
}
.modal-text-area-data-1{

}
.text-modal-area1{

    box-sizing: border-box;
    width: 100%;
    margin: 0;
    height: 100px;
padding: 0 calc(16px / 2);
font-family: inherit;
font-size: 16px;
line-height: calc(16px + 16px / 2);
color: #888;
background-color: none;
border: 0;
outline: 0 !important;
resize: none;
overflow: hidden;
}
.modal-text-area-data-1{
    padding: 20px;
}
.upload-options{

    display: flex;
    gap: 1rem;
}
.upload-options>input{
    border: 1px solid;
    padding: 11px;
    /* cursor: pointer; */

}
.text-area-modal-2{

    box-sizing: border-box;
    width: 100%;
margin: 0;
height: 20px;
padding: 0 calc(16px / 2);
font-family: inherit;
font-size: 16px;
line-height: calc(16px + 16px / 2);
color: #888;
background-color: none;
border: 0;
outline: 0 !important;
resize: none;
overflow: hidden;
}
.section-1-text{
    padding: 20px;
}
.check-box-post-btn{
    padding:  20px;
    padding: 20px;
    display: flex;
    justify-content: space-between;

}
.post-btn-dis{

    padding: 7px 16px;
    border: 1px solid;
    color: white;
    background: #bc0000;
    border-radius: 15px;
}

.lkjDVC {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-width: unset !important;
    max-width: unset !important;
    height: 48px;
    border: dashed 2px #0658c2;
    padding: 8px 16px 8px 8px;
    border-radius: 5px;
    cursor: pointer;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
}
@media only screen and (max-width: 500px) {

.check-box-post-btn {
    gap: 20px;
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.ReactModal__Content {
    inset: 62px 48px !important;
}
}
@media only screen and (max-width: 380px) {


    .ReactModal__Content {
        /* inset: 120px 48px !important; */
        /* height: 80vh; */
    }
    }
