.fle1-nav1{
    padding: 10px 21px;
    display: flex;
    align-items: center;
    /* gap: 10rem; */
    justify-content: space-between;
}

.login-b1{
    cursor: pointer;
    border: none;
    font-size: 18px;
    background: transparent;
    color:white;
    font-weight: bold;
  }

  @media only screen and (max-width: 600px) {

  .log-sig-btn-1{
display: none !important;
  }

}

.log-sig-btn-1{
display: flex;
    gap: 1rem;
}
.head-mobile-menu1{
  /* width: 200px; */
  padding: 30px;
}