.account-bg {
  background-image: url("../../Img/20210204_a547.jpg");
  background-position: center;
  background-size: cover;
  min-height: 618px;
}
.Section1-Account {
  padding: 100px 0px 50px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.Section2-Account {
  width: 90%;
}
.account-h1 {
  color: #122b46;
  font-size: 30px;
  font-weight: 600;
}
.acc-btn-1 {
  border-radius: 20px;
  background-color: #d2d2d2;
  padding: 10px 17px;
  color: black;
  font-size: 14px;
}
.acc-btn-1:hover {
  background-color: #bc0000;
  color: white;
}
.Section3-Account {
  display: flex;
  justify-content: space-between;
}
.btn-d {
  gap: 5px;

  display: flex;
  align-items: center;
}
.section4-Account {
  border-radius: 5px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  border: 1px solid;
  background-color: white;
}

.acc-h2 {
  font-size: 15px;
  font-weight: 500;
}
.acc-h3 {
  font-size: 15px;
  font-weight: 500;
}
.lg-div1 {
  cursor: pointer;
  display: flex;
  gap: 10px;
  padding: 20px 10px;
}
.lg-div2 {
  cursor: pointer;

  padding: 20px 10px;
  display: flex;
  gap: 10px;
}
.lg-acc1 {
  width: 20%;
  border-right: 1px solid;
}
.rh-acc1 {
  width: 80%;
}
.acc-TabPanel1 {
}
.rh-acc-1 {
  /* padding: 50px; */
}
.acc-h4 {
  font-size: 20px;
  padding: 10px 0px;
}
.acc-p1 {
  font-size: 15px;
}

.acc-input-pas{
  width: 100%;
  padding: 0px 10px;
  height: 40px;
}
.rh-acc-2{
  display: flex;
  padding: 15px 0px;
}
.password-input-container {
  position: relative;

}
.password-input-container>input{
 
  /* width: calc(100% - 5px);
    height: 33px;
    padding: 5px; */
}
.email-input-acc>input{
  /* width: 100%;
    height: 33px;
    padding: 5px; */
}
.email-input-acc{

    /* padding: 15px 0px; */
}

.password-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.password-icon svg {
  width: 20px;
  height: 20px;
  fill: gray;
}
.acc-h5{
  color: #bc0000;
  font-weight: 400;
  font-size: 15px;
  cursor: pointer;
  padding: 10px 0px;
}
.label-1acc{
  line-height: 2;
}
.acc-TabPanel1{
  padding: 50px;
}
.tab-account-1{
  padding: 50px;
}
.input-box-1-acc{

  width: 100%;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  
border-radius: 4px;
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box;
padding-left: 20px;
padding-right: 20px;
padding-top: 12px;
padding-bottom: 12px;
}




.acc-p2{
  padding: 0px 10px;
  font-size: 15px;
}
@media only screen and (max-width: 650px) {
  .acc-a1>p {
    padding: 4px !important;
    font-size: 11px !important;
}
.acc-a2 >p {
  padding: 4px !important;
  font-size: 11px !important;
}


.acc-p2 {
  padding: 0px 10px;
  font-size: 12px;
}

}
.email-input-acc {
  /* padding: 15px 0px; */
}
.addyour{
padding: 10px 0px;
}
@media only screen and (max-width: 970px) {

  .section4-Account {
  
      flex-wrap: wrap;
  }
  .lg-acc1 {
      width: 100%;
      border-right: 0px;
  }
  .acc-TabPanel1 {
      padding: 10px;
  }
  .Section3-Account {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 1rem;
    
    }
    .tablist-2-acc{
    display: flex;
    justify-content: center;
  }
  .tab-2-delete-acc {
    padding: 10px !important;
}
.acc-p3 {
  padding: 0px 10px !important;
  font-size: 10px !important;
}
  }
 .tab-2-delete-acc{
  padding: 50px;
 }
 


.info-sv2{
  padding: 15px 0px;
}
.acc-p3{
  padding: 0px 10px;
  font-size: 15px;
}
.checkbox-1-acc{

  display: flex;
  gap: 1rem;
  padding: 10px 0px;
}
.checkbox-1-acc>label{
font-size: 15px;
}
.delete-acc-btn{
  padding: 10px 15px;
  border-radius: 20px;
  
  background:#bc0000ab;
  color: white;
}
.delete-acc-btn:hover{
  padding: 10px 15px;
  border-radius: 20px;
  
  background:#bc0000;
  color: white;
}
.delete-h1-acc{
color: #bc0000;
     font-size: 25px;
  
}

.acc-2-a{
  padding: 15px 0px;
}
.acc-a1{
  border: 1px solid  #ccc;
display: flex;
border-radius: 5px;

}
.acc-a1>p{
padding: 10px;
font-size: 15px;

  
}
.acc-a1>span{
background-color: #f7ba45;
display: flex;
justify-content: center;
align-items: center;
color: white;
padding: 10px;
border-top-left-radius: 3px;
border-bottom-left-radius: 3px;
}
.acc-a2{
  border: 1px solid  #ccc;
display: flex;
border-radius: 5px;

}
.acc-a2>p{
padding: 10px;
font-size: 15px;
  
}
.acc-a2>span{
background-color:#007cff;
display: flex;
justify-content: center;
align-items: center;
color: white;
padding: 10px;
border-top-left-radius: 3px;
border-bottom-left-radius: 3px;
}
#my-checkbox:checked {
  background-color:red;
}

/* change the color of the checkmark when it is selected */
#my-checkbox:checked + label::before {
  color: red;
}
.bb2 {
  border-bottom: 1px solid red;
  /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
}
.bb3 {
  border-bottom: transparent;
}
.save-acc-btn{

  padding: 10px 25px;
  border-radius: 20px;
  background: #bc0000ab;
  color: white;
}
.save-acc-btn:hover{

background: #bc0000;
}
.save-btn1-div{
  padding: 15px 0px;
  display: flex;
  justify-content: center;

}


/*  */

