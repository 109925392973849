$base-height: 377px;
$base-dimension: 16px;
$base-border-radius: 12px;
$base-button-size: 52px;
$primary-color:#bc0000;
$accent-color: #f6f7f9;
$background-color: #fff;
$font-color: #888;

@mixin chabox-container {
  display: flex;
  position: fixed;
  box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  flex-direction: column;
}

@mixin chatbox-header {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: $base-dimension;
  color: $background-color;
  background-color: $primary-color;
  align-items: center;
  justify-content: space-around;
}

@mixin chatbox-main {
  box-sizing: border-box;
  width: 100%;
  padding: calc(2 * #{$base-dimension}) #{$base-dimension};
  line-height: calc(#{$base-dimension} + #{$base-dimension} / 2);
  color: $font-color;
  text-align: center;
}

@mixin chatbox-footer {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: $base-dimension;
  border-top: 1px solid #ddd;
  align-items: center;
  justify-content: space-around;
}

@mixin chatbox-floating-button {
  position: fixed;
  bottom: 0;
  right: 0;
  width: $base-button-size;
  height: $base-button-size;
  color: $background-color;
  background-color: $primary-color;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 12px 15px 20px 0 rgba(46, 61, 73, 0.15);
  border: 0;
  border-radius: 50%;
  cursor: pointer;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  background-color: $accent-color;
}


button {
  color: inherit;
  background-color: transparent;
  border: 0;
  outline: 0 !important;
  cursor: pointer;

  &.chatbox-open {
    @include chatbox-floating-button;
    margin: $base-dimension;
  }

  &.chatbox-close {
    @include chatbox-floating-button;
    display: none;
    margin: $base-dimension calc(2 * #{$base-dimension} + #{$base-button-size})
      $base-dimension $base-dimension;
  }
}

.text-chat-area{
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  height: calc(#{$base-dimension} + #{$base-dimension} / 2);
  padding: 0 calc(#{$base-dimension} / 2);
  font-family: inherit;
  font-size: $base-dimension;
  line-height: calc(#{$base-dimension} + #{$base-dimension} / 2);
  color: $font-color;
  background-color: none;
  border: 0;
  outline: 0 !important;
  resize: none;
  overflow: hidden;

  &::placeholder {
    color: $font-color;
  }
}

.chatbox-popup {
  @include chabox-container;
  display: none;
  bottom: calc(2 * #{$base-dimension} + #{$base-button-size});
  // right: $base-dimension;
  width: 320px;
  height: auto;
  background-color: $background-color;
  border-radius: $base-dimension;

  .chatbox-popup__header {
    @include chatbox-header;
    border-top-right-radius: $base-border-radius;
    border-top-left-radius: $base-border-radius;

    .chatbox-popup__avatar {
      margin-top: -32px;
      background-color: $primary-color;
      border: 5px solid rgba(0, 0, 0, 0.1);
      border-radius: 50%;
    }
  }

  .chatbox-popup__main {
    @include chatbox-main;
  }

  .chatbox-popup__footer {
    @include chatbox-footer;
    border-bottom-right-radius: $base-border-radius;
    border-bottom-left-radius: $base-border-radius;
  }
}

.chatbox-panel {
  @include chabox-container;
  display: none;
  top: 100px;
  right: 0;
  bottom: 31px;
  border-radius: 15px;
  width: $base-height;
  background-color: $background-color;

  .chatbox-panel__header {
    @include chatbox-header;
    flex: 0 0 auto;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }

  .chatbox-panel__main {
    @include chatbox-main;
    flex: 1 1 auto;
  }

  .chatbox-panel__footer {
    @include chatbox-footer;
    flex: 0 0 auto;
  }
}
.close-m{

    display: flex;
    justify-content: space-evenly;
}
.user-team-svg{

    
    border: 1px solid white;
    padding: 10px;
    border-radius: 40px;
}
.sec-chat-i{

  display: flex;
  justify-content: flex-end;
  padding: 0px 15px;

}
@media only screen and (max-width: 600px) {


}

.yu{

  display: flex;
  gap: 0.5rem;
}