@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
* {
  margin: 0;
  padding: 0;

  font-family: "Roboto", sans-serif;
}
.nav-bg {
  background-image: url("../../Img/20210204_a547.jpg");
  background-position: center;
  background-size: cover;
  /* position: fixed;
z-index: 5; */

  background-position: center;
  background-size: cover;
  width: 100%;
}
.fix-pro-nav {
  z-index: 50;
  position: fixed;
  width: 100%;
  background-color: white;
}
.ul-n {
  display: flex;
  gap: 2rem;
  border: 2px solid #bc0000;
  padding: 1px 16px;
  border-radius: 20px;
  background-color: #bc0000;
  white-space: pre;
  color: white;
  align-items: center;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}
.nav-a {
  color: white;
}
a {
  /* color:black;  */
  text-decoration: none;
}
.nav-logo {
  cursor: pointer;
  width: 200px;
}
.fle1-nav {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  /* gap: 10rem; */
  justify-content: space-between;
}
.login-b {
  cursor: pointer;
  border: none;
  font-size: 14px;
  background: transparent;
  color: #bc0000;
  font-weight: bold;
}
.nav-bb {
  display: flex;
  gap: 1rem;
}
.sign-bb {
  color: white;
  background-color: #bc0000;
  padding: 7px 10px;
  font-size: 12px;
  border: none;
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
}

@media only screen and (max-width: 950px) {
  /* .fle1-nav {
    padding: 10px 20px;
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;

    flex-wrap: wrap; */
  /* } */
  .ul-n {

  }

  .pdf-about-pages > img {
    width: 315px;
  }
  .img-home-g {
    width: 362px;
    height: auto;
    object-fit: cover;
    border-radius: 15px;
  }
  .img-home-g1 {
    width: 362px;
    height: auto;
    object-fit: cover;
    border-radius: 15px;
  }
  .img-home-g2 {
    width: 362px;
    height: auto;
    object-fit: cover;
    border-radius: 15px;
  }
  .login-b {
    cursor: pointer;
    border: none;
    font-size: 15px;
    background: transparent;
    color: #bc0000;
    font-weight: bold;
  }
  .sign-bb {
    font-weight: bold;
    color: white;
    background-color: #bc0000;
    padding: 7px 10px;
    font-size: 13px;
    border: none;
    border-radius: 15px;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    cursor: pointer;
  }
}
@media only screen and (max-width: 800px) {
  .ul-n {
    gap: 1.5rem;
  }
  .about-pp2 {
    font-size: 20px;
    text-align: center;
  }

  .about-pp21 {
    text-align: center;
  }
}
.gap-sv {
}
.gap-svg-h {
  display: flex !important;
  gap: 1rem;
  align-items: center;
}

/* Center the planet */
.bodys {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Boring button styles */
a.button {
  display: inline-block;
  box-sizing: border-box;
  border: none;
  display: flex;
  gap: 10px;
  font-size: 17px;
  cursor: pointer;
  align-items: center;
}

a.button:active {
  filter: brightness(75%);
}

/* Dropdown styles */
.dropdown {
  position: relative;
  padding: 0;
  margin-right: 1em;

  border: none;
}

.dropdown summary {
  list-style: none;
  list-style-type: none;
}

.dropdown > summary::-webkit-details-marker {
  display: none;
}

.dropdown summary:focus {
  outline: none;
}

.dropdown summary:focus a.button {
}

.dropdown summary:focus {
  outline: none;
}

.dropdown ul {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  margin: 20px 0 0 0;
  padding: 20px 0;
  width: 160px;
  left: 50%;
  margin-left: calc((var(--dropdown-width) / 2) * -1);
  box-sizing: border-box;
  z-index: 2;

  background: var(--dropdown-background);
  border-radius: 6px;
  list-style: none;
}

.dropdown ul li {
  padding: 0;
  margin: 0;
}

.dropdown ul li a:link,
.dropdown ul li a:visited {
  display: inline-block;
  padding: 10px 0.8rem;
  width: 100%;
  box-sizing: border-box;

  color: #444444;
  text-decoration: none;
}

.dropdown ul li a:hover {
  background-color: #bc0000da;
  color: var(--dropdown-background);
}

/* Dropdown triangle */
.dropdown ul::before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  top: -10px;
  left: 50%;
  margin-left: -10px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent var(--dropdown-background) transparent;
}

/* Close the dropdown with outside clicks */
.dropdown > summary::before {
  display: none;
}

.dropdown[open] > summary::before {
  content: " ";
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}

@media only screen and (max-width: 945px) {
  .fix-pro-nav {
    /* position:unset !important; */
    width: 100%;
    background-color: white;
  }
}

@media only screen and (max-width: 600px) {
  .sc-bcXHqe {
    display: none;
  }
  .mobile-menu-nav {
    display: flex !important;
  }

  .bodys {
    /* display: none; */
  }
  a.button {
    font-size: 15px;
  }
  .ul-n {
    display: none;
  }
  .fle1-nav {
    padding: 10px 20px;
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .nav-logo {
    cursor: pointer;
    width: 100px;
  }
}
.sidebar-navbar-mobile{
  transition: 0.5s;

}
