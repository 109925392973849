.kWjcRA {
    background-color: #bc0000 !important;
    color:white ;
}

.sc-gswNZR>svg {
color: white;
}
.cdtnjx {
color: white !important;
/* top: 10px !important;
right: 30px !important; */
position: unset !important;

}
.prjCa {
    width: 225px !important;
    height: 100vh;
    padding: 24px;
    position: relative;
    background-color: #bc0000 !important;
    color: white;
}

.fgZRAj {
    color: white !important;
    /* right: -37px !important; */
    position: unset !important;
}
