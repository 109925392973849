.bDZlYX {
position: unset !important;
display: none !important;

}
.dXVOPX {
    min-width: unset !important; 
    max-width: unset !important; 

}
.downloadLink{
    display: none !important;
}

@media only screen and (max-width: 600px) {
 
    .body-fix-h{
        overflow: unset !important;
        height:  unset !important;
      }
      .main-y{
        overflow-y: unset !;
        width:  unset !important;
      }
    
     
     
      .wrapper {
        display:  unset !important;
        height:  unset !important;
      }

}
.body-fix-h{
  overflow: hidden;
  height: 100vh;
}
.main-y{
  overflow-y: auto;
  width: 100%;
}



.wrapper {
  display: flex;
  height: 100%;
}
