.bg-stream {
  background-image: url("../../Img/20210204_a547.jpg");
  background-position: center;
  background-size: cover;
  min-height: 618px;
}
.live-s-f {
  display: flex;
}
.live-head-1 {
  width: 100%;
  padding: 100px 0px 0px 0px;
}
.live-head-2 {
  width: 100%;
  display: flex;
  justify-content: center;
}
.live-head-3 {
  width: 90%;
}
.live-head-h {
  padding: 15px 0px;
}

.live-head-h > h1 {
  font-size: 30px;
}

.live-head-p {
  padding: 10px;
  display: flex;
  justify-content: center;
}
.live-head-p > p {
  font-size: 18px;
  text-transform: capitalize;
}
.card-container-stream {
  display: flex;

  flex-wrap: wrap;
  justify-content: center;
}

.stream-card {
  width: 30%;
  margin: 1%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  text-align: center;
}

.stream-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.stream-card > iframe {
  width: 100%;
  height: 300px;
}
.stream-card > video {
    width: 100%;
    height: 300px;
    background-color: black;
  }
.stream-comtainer {
  width: 100%;
  display: flex;
  justify-content: center;
}
.stream-videos {
  width: 90%;
}
@media only screen and (max-width: 1000px) {
  .stream-card {
    width: 70%;
  }
  .card-container-stream {
gap: 2rem;
  }
}
@media only screen and (max-width: 600px) {
    .stream-card {
      width: 100%;
    }
    .stream-card:hover{
        transform: unset !important;
        

    }
  } 
  .description-cards-stream{

      padding: 15px;
    }
    .stream-card:hover{
        transform: scale(1.1);
        transition: all 0.5s;

    }