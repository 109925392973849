.bg-module{

    background-image: url('../../Img/20210204_a547.jpg');
    background-position: center;
    background-size: cover;
    min-height: 100vh
}
.module-div {
  display: flex;
  width: 100%;
}
.module-a2 {
  background-image: url("../../Img//bg-module.jpg");
  background-position: center;
  background-size: cover;
  height: 150px;
  display: flex;
  align-items: center;
  padding: 20px;

  /* padding: 20px */
}
.module-a1 {
  /* width: 90%; */
  padding: 50px 0px 0px 0px;
}

.module-h1 {
  color: white;

  font-size: 30px;

  text-transform: uppercase;
  padding: 10px 0px;
}
.module-h2 {
  color: white;
  text-transform: uppercase;
  font-size: 15px;
}
.module-a3 {
}
.modules11 {
  /* width: 100%;
display: flex;
justify-content: center; */
}

.module-a4{
    width: 100%;
    display: flex;
    justify-content:flex-start;
}
.module-a5{
    width: 65%;
    padding: 10px 25px 60px 25px

}
.module-h3{
    font-size: 15px;
    color:#313337;
    font-weight: 100;

}
.module-h4{
    font-size: 15px;
    color:#313337;
    font-weight: 100;


}
  .module-a6{
    
    box-sizing: border-box;
    width: 100%;
    border-radius: 5px;
    display: flex;
    align-items: center;

    padding: 17px;
 
    background-color: #4444443d;
    justify-content: space-between;
  }
  
  
  
  .progress-value-Modules{

      animation: load 3s normal forwards;
      box-shadow: 0 10px 40px -10px #fff;
      border-radius: 100px;
      background: #bc0000;
      height: 4px;
      width: 0;
      transform: translateX(-5px);
    }
    .progress-Modules{

        background: black;
        justify-content: flex-start;
        border-radius: 100px;
        align-items: center;
        position: relative;
        padding: 0 5px;
        display: flex;
        height: 4px;
        width: 200px;
    }
    .module-a7{
        padding: 30px 0px ;
    }
    .module-a8{
        padding: 10px 10px ;
    }
    .module-a8>p{ 
        font-size: 18px;
        font-weight: 100;
    }

    .module-a9{
        padding: 10px 10px ;
    }
    .module-a9>p{ 
        font-size: 18px;
        font-weight: 100;
    }
    .module-a10{
        padding: 10px 10px ;
    }
    .module-a10>p{ 
        font-size: 18px;
        font-weight: 100;
    }


    .module-a11{
        padding: 10px 10px ;
    }
    .module-a11>p{ 
        font-size: 18px;
        font-weight: 100;
    }


    .module-a12{
        padding: 10px 10px ;
    }
    .module-a12>p{ 
        font-size: 18px;
        font-weight: 100;
    }


.module-h5{
    font-size: 15px;
}
.module-h6{
    font-size: 15px;
    padding: 20px 0px;
}
.module-h11{
    font-size: 15px;
    padding: 20px 0px;
}
/* .module-h6{
  
font-size: 19px;
    font-weight: 400;
}
 */


.module-a13{

    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding: 28px;
    background: #a9a9a94a;
}
.module-h7{
cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    text-decoration-line: line-through;

}
.module-h7:hover{
color: #bc0000;

}
.module-a14{
    border-radius: 5px;
    gap: 1rem;
    display: flex;
    padding: 28px;
    background: #a9a9a94a;
    align-items: center;
    justify-content: space-between;
}
.module-h8{
cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
    /* text-decoration-line: line-through; */
}
.module-h8:hover{
color: #bc0000;

}





.modules-title{

    gap: 1rem;
    display: flex;
    flex-direction: column;
}




        /* card - css */
        .card-SideMoudle{
            width: 300px;
            height: 500px;
            box-shadow: 2px 2px 5px #ccc;
            position: relative; /* fix property */
            overflow: hidden; /* fix property */
            border-radius: 10px;
          }
          
          .card-SideMoudle>img {
            width: 100%;
            height: 100%;
            object-fit: cover; /* fix property */
          }
          
          .card-description {
            position: absolute; /* fix property */
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(255, 255, 255, 0.8);
            padding: 10px;
            text-align: center; /* fix property */
          }
          .module-a20{

              position: fixed;
              /* left: 0; */
              /* top:100px; */
              /* right: 0px; */
              display: flex;
              justify-content: flex-end;
              margin: 10px 32px;
              width: fit-content;
            }
            .btn-1-Modules{
           
                padding: 8px 21px;
                border-radius: 15px;
                margin: 5px 0px;
                color: white;
                background-color: #bc0000;
            
            }
            .section-1-module{
                display: flex;
            }
            .module-h15{
                font-size: 15px;
                padding: 10px;
            }
/* media query section */

    @media only screen and (max-width: 1100px) {

        .module-a020 {
           
            width:unset !important;

          
            }
        .module-a20 {
      position: unset !important;
        }
        .section-1-module {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
        }
        .module-a5 {
            width: 80%;
            padding: 10px 25px 60px 25px
        }
        .module-a4 {
            width: 100%;
            display: flex;
            justify-content: flex-start;
        }
        .section-1-module {
            display: flex;
            flex-direction: column-reverse;
            align-content: center;
            align-items: center;
        }
        .module-a6 {
gap: unset !important;
        }
    }
    @media only screen and (max-width: 600px) {
        .module-div {
            
        display: unset !important;
        }
        .module-a8>p {
        
        font-size: 15px;
        }
        .module-a9>p {
        
            font-size: 15px;
            }
            .module-a10>p {
        
                font-size: 15px;
                }
                .module-a11>p {
        
                    font-size: 15px;
                    }
                    .module-a12>p {
        
                        font-size: 15px;
                        }

        }

.module-a020{

    width: 35%;
        display: flex;
        justify-content: center;
    }