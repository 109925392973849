// {swiper}
  .swiper {
    width: 100%;
    height: 70vh;
    // overflow: visible;
  }
  
  .swiper-wrapper {
    
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    // background: white;
    transition: all .3s ease;
    
    &.swiper-slide-active {
      // background: linear-gradient(135deg, #000000e6, #000000);
      // background: linear-gradient(45deg, rgb(246, 146, 89), rgb(241, 105, 117));
      transform: scale(1.4);

      z-index: 2
    }
  }
  
  /* ARROWS */
 
  /* PAGINATION */
  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color:#000;
    opacity: 1;
    background: rgba(0,0,0,0.2);
    
    &.swiper-pagination-bullet-active {
      color:#fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      
      // background: linear-gradient(45deg, rgb(246, 146, 89), rgb(241, 105, 117));
    }
  }
  
  .head-swiper{
    // overflow: hidden;
  }
  
 .swiper-slide>img{
  width: 250px;
  }
  .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    content: 'next';
    color: black ;
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: 'prev';
  color: black;
}
@media only screen and (max-width: 900px) {


.swiper {
  width: 100%;
  height: 280px;
  overflow: hidden;
}

  .img-g {
    padding: 0px !important;

  }
  .swiper-border-1>h1 {
    cursor: pointer;
    font-size: 12px;
}
.swiper-border-1>h1 {
  width: 170px;
}
.swiper-border-1>p {
  font-size: 10px;
}
.episode-p-h-2 {
  width: 90%;
}
.swiper-border-1 {
  border: 1px solid;
  display: flex;
  gap: 1rem;
  padding: 8px 7px;
  flex-direction: column;
  background: white;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  align-items: center;
}
.swiper-slide-active{
  // width: 216.333px !important;
}
}

.swiper-data1{
//  padding: 100px 0px;
}


@mixin object-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

$circleSize: 165px;
$radius: 100px;
$shadow: 0 0 10px 0 rgba(255,255,255,.35);
$fontColor: rgb(250,250,250);

.profile-pic {
  width: fit-content;
  color: transparent;
  transition: all .3s ease;
  @include object-center;
  position: relative;
  transition: all .3s ease;
  
  input {
    display: none;
  }
  
  img {
    position: absolute;
    object-fit: cover;
    width: $circleSize;
    height: $circleSize;
    box-shadow: $shadow;
    border-radius: $radius;
    z-index: 0;
    
    border: 3px solid #fff;
    max-width: 170px;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;

  }
  
  .-label {
    cursor: pointer;
    height: $circleSize;
    width: $circleSize;
  }
  
  &:hover {
    .-label {
      @include object-center;
      background-color: rgba(0,0,0,.8);
      z-index: 10000;
      color: $fontColor;
      transition: background-color .2s ease-in-out;
      border-radius: $radius;
      margin-bottom: 0;
    }
  }
  
  span {
    display: inline-flex;
    padding: .2em;
    height: 2em;
    gap: 10px;
  }
}

/////////////////////////
// Body styling 🐾
/////////---------->


              // Emi details dashboard


              $primary-color: #FF6B6B;
              $primary-color-hover: scale-color($primary-color, $lightness: 32%);
       
.example-header {
  background: #3D4351;
  color: #FFF;
  font-weight: 300;
  padding: 3em 1em;
  text-align: center;
  h1 {
      color: #FFF;
      font-weight: 300;
      margin-bottom: 20px
  }
  p {
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 3px;
      font-weight: 700;
  }
}
.container-fluid {
  .row {
      padding: 0 0 4em 0;
      &:nth-child(even) {
          background: #F1F4F5;
      }
  }
}

.example-title {
  text-align: center;
  margin-bottom: 60px;
  padding: 3em 0;
  border-bottom: 1px solid #E4EAEC;
  p {
      margin: 0 auto;
      font-size: 16px;
      max-width: 400px;
  }
}

/*==================================
  TIMELINE
==================================*/

  /*-- GENERAL STYLES
  ------------------------------*/
  .timeline {
      line-height: 1.4em;
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
      h1, h2, h3, h4, h5, h6 {
          line-height: inherit;
      }
  }

  /*----- TIMELINE ITEM -----*/

  .timeline-item {
      padding-left: 40px;
      position: relative;
      &:last-child {
          padding-bottom: 0;
      }
  }

  /*----- TIMELINE INFO -----*/

  .timeline-info {
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 3px;
      margin: 0 0 .5em 0;
      text-transform: uppercase;
      white-space: nowrap;
  }
  /*----- TIMELINE MARKER -----*/

  .timeline-marker {
      position: absolute;
      top: 0; bottom: 0; left: 0;
      width: 15px;
      &:before {
          background: $primary-color;
          border: 3px solid transparent;
          border-radius: 100%;
          content: "";
          display: block;
          height: 15px;
          position: absolute;
          top: 4px;
           left: -3px;
          width: 15px;
          transition: background 0.3s ease-in-out,
                  border 0.3s ease-in-out;
      }
      &:after {
          content: "";
          width: 3px;
          background: #CCD5DB;
          display: block;
          position: absolute;
          top: 24px; bottom: 0; left: 6px;
      }
      .timeline-item:last-child &:after {
          content: none;
      }
  }
  .timeline-item:not(.period):hover .timeline-marker:before {
      background: transparent;
      border: 3px solid $primary-color;
  }

  /*----- TIMELINE CONTENT -----*/

  .timeline-content {
      padding-bottom: 40px;
      p:last-child {
          margin-bottom: 0;
      }
  }

  /*----- TIMELINE PERIOD -----*/
  
  .period {
      padding: 0;
      .timeline-info {
          display: none;
      }
      .timeline-marker {
          &:before {
              background: transparent;
              content: "";
              width: 15px;
              height: auto;
              border: none;
              border-radius: 0;
              top: 0;
              bottom: 30px;
              position: absolute;
              border-top: 3px solid #CCD5DB;
              border-bottom: 3px solid #CCD5DB;
          }
          &:after {
              content: "";
              height: 32px;
              top: auto;
          }
      }
      .timeline-content {
          padding: 40px 0 70px;
      }
      .timeline-title {
          margin: 0;
      }
  }

  /*----------------------------------------------
      MOD: TIMELINE SPLIT
  ----------------------------------------------*/

      .timeline-split {
          @media (min-width: 768px) {
              .timeline {
                  display: table;
              }
              .timeline-item {
                  display: table-row;
                  padding: 0;
              }
              .timeline-info,
              .timeline-marker,
              .timeline-content,
              .period .timeline-info {
                  display: table-cell;
                  vertical-align: top;
              }
              .timeline-marker {
                  position: relative;
              }
              .timeline-content {
                  padding-left: 30px;
              }
              .timeline-info {
                  padding-right: 30px;
              }
              .period .timeline-title {
                  position: relative;
                  left: -45px;
              }
          }
      }

  /*----------------------------------------------
      MOD: TIMELINE CENTERED
  ----------------------------------------------*/

      .timeline-centered {
          @extend .timeline-split;
          @media (min-width: 992px) {
              &,
              .timeline-item,
              .timeline-info,
              .timeline-marker,
              .timeline-content {
                  display: block;
                  margin: 0;
                  padding: 0;
              }
              .timeline-item {
                  padding-bottom: 40px;
                  overflow: hidden;
              }
              .timeline-marker {
                  position: absolute;
                  left: 50%;
                  margin-left: -7.5px;
              }
              .timeline-info,
              .timeline-content {
                  width: 50%;
              }
              > .timeline-item:nth-child(odd) .timeline-info {
                  float: left;
                  text-align: right;
                  padding-right: 30px;
              }
              > .timeline-item:nth-child(odd) .timeline-content {
                  float: right;
                  text-align: left;
                  padding-left: 30px;
              }    
              > .timeline-item:nth-child(even) .timeline-info {
                  float: right;
                  text-align: left;
                  padding-left: 30px;
              }
              > .timeline-item:nth-child(even) .timeline-content {
                  float: left;
                  text-align: right;
                  padding-right: 30px;
              }
              > .timeline-item.period .timeline-content {
                  float: none;
                  padding: 0;
                  width: 100%;
                  text-align: center;
              }
              .timeline-item.period {
                  padding: 50px 0 90px;
              }
              .period .timeline-marker:after {
                  height: 30px;
                  bottom: 0;
                  top: auto;
              }
              .period .timeline-title {
                  left: auto;
              }
          }
      }

  /*----------------------------------------------
      MOD: MARKER OUTLINE
  ----------------------------------------------*/
      
      .marker-outline {
          .timeline-marker {
              &:before {
                  background: transparent;
                  border-color: $primary-color;
              }
          }
          .timeline-item:hover .timeline-marker:before {
              background: $primary-color;
          }
      }
               
             

             

                

         