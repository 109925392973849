

#hexGrid {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    font-family: sans-serif;
    list-style-type: none;
  }
  
  .hex {
    position: relative;
    visibility:hidden;
    outline:1px solid transparent; /* fix for jagged edges in FF on hover transition */
    transition: all 0.5s;
    backface-visibility: hidden;
    will-change: transform;
    transition: all 0.5s;
  }
  .hex::after{
    content:'';
    display:block;
    padding-bottom: 86.602%;  /* =  100 / tan(60) * 1.5 */
  }
  .hexIn{
    position: absolute;
    width:96%;
    padding-bottom: 110.851%; /* =  width / sin(60) */
    margin: 2%;
    overflow: hidden;
    visibility: hidden;
    outline:1px solid transparent; /* fix for jagged edges in FF on hover transition */
    -webkit-transform: rotate3d(0,0,1,-60deg) skewY(30deg);
        -ms-transform: rotate3d(0,0,1,-60deg) skewY(30deg);
            transform: rotate3d(0,0,1,-60deg) skewY(30deg);
      transition: all 0.5s;
  }
  .hexIn * {
    position: absolute;
    visibility: visible;
    outline:1px solid transparent; /* fix for jagged edges in FF on hover transition */
  }
  .hexLink {
      display:block;
      width: 100%;
      height: 100%;
      text-align: center;
      color: #fff;
      overflow: hidden;
      -webkit-transform: skewY(-30deg) rotate3d(0,0,1,60deg);
          -ms-transform: skewY(-30deg) rotate3d(0,0,1,60deg);
              transform: skewY(-30deg) rotate3d(0,0,1,60deg);
  }
  
  
  
  
  /*** HEX CONTENT **********************************************************************/
  .hex img {
    left: -100%;
    right: -100%;
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
  
  .hex h1, .hex p {
    width: 100%;
    padding: 5%;
    box-sizing:border-box;
    font-weight: bold;
    // opacity: 0;
  }
  
  #demo1 {
    color: #F5CE95;
    text-transform: capitalize;
      text-align: center;
    bottom: 50%;
    padding-top:50%;
    font-size: 1.5em;
    z-index: 1;
  }
  .hex h1:before, .hex h1:after {
    display: inline-block;
    margin: 0 0.5em;
    width: 0.25em;
    height: 0.03em;
    background: #ffffff;
    content: '';
    vertical-align: middle;
    transition: all 0.3s;
    text-align:center;
  }
  #demo2 {
    top: 30%;
    text-align: center;
    text-transform: uppercase;
        
    } 
  #demo3 {
  top: 20%;
  text-align: center;
  text-transform: uppercase;
      
  }
  
  .img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: black;
    background-position: center center;
    background-size: cover;
    overflow: hidden;
  -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  }
  
  .img:before, .img:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    // opacity: 0;
    transition: opacity 0.5s;
  }
  .img:before {
    background: rgba(22, 103, 137, 0.3)
  }
  .img:after {
    background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.5), transparent);
  }
  
  /*** HOVER EFFECT  **********************************************************************/
  
  
  
  .hexLink:hover h1, .hexLink:focus h1,
  .hexLink:hover p, .hexLink:focus p{
  opacity:1;
  transition: 0.8s;
  }
  
  
  .hexIn:hover .img:before,
  .hexIn:hover .img:after,
  .hexIn:hover .hexLink {
    // opacity: 1;
  }
  
  
  /*** HEXAGON SIZING AND EVEN ROW INDENTATION *****************************************************************/
  @media (min-width:1201px) { /* <- 5-4  hexagons per row */
    #hexGrid{
      padding-bottom: 4.4%
    }
    .hex {
      width: 20%; /* = 100 / 5 */
    }
    .hex:nth-child(9n+6){ /* first hexagon of even rows */
      margin-left:10%;  /* = width of .hex / 2  to indent even rows */
    }
  }
  
  @media (max-width: 1200px) and (min-width:901px) { /* <- 4-3  hexagons per row */
    #hexGrid{
      padding-bottom: 5.5%;
      font-size: 13px;
    }
    .hex {
      width: 25%; /* = 100 / 4 */
    }
    .hex:nth-child(7n+5){ /* first hexagon of even rows */
      margin-left:12.5%;  /* = width of .hex / 2  to indent even rows */
    }
  }
  
  @media (max-width: 900px) and (min-width:601px) { /* <- 3-2  hexagons per row */
    #hexGrid{
      padding-bottom: 7.4%;
      font-size: 14px;
    }
    .hex {
      width: 33.333%; /* = 100 / 3 */
    }
    .hex:nth-child(5n+4){ /* first hexagon of even rows */
      margin-left:16.666%;  /* = width of .hex / 2  to indent even rows */
    }
  }
  
  @media (max-width: 600px) { /* <- 2-1  hexagons per row */
    #hexGrid{
      padding-bottom: 11.2%;
      font-size: 12px;
    }
    .hex {
      width: 50%; /* = 100 / 3 */
    }
    .hex:nth-child(3n+3){ /* first hexagon of even rows */
      margin-left:25%;  /* = width of .hex / 2  to indent even rows */
    }
  }
  
  @media (max-width: 400px) {
      #hexGrid {
          font-size: 8px;
      }
    
  
    
   
  }
  