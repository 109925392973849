.head-about1 {
  /* padding: 14px 0px; */
}
.about-h1 {
  color: #bc0000;
  text-align: center;
  font-weight: 400;
  font-size: 24px;
}
.bg-blog {
  background-image: url("../../Img/20210204_a547.jpg");
  background-position: center;
  background-size: cover;
  min-height: 618px;

  /* height: 89vh; */
}
/* .w-o1{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .w-oo1{
        margin-top: 20px;
        width: 80%;
        gap: 2rem;
        display: flex;
        flex-direction: column;
    }
    .para-about21{
    margin-top: 30px;
    padding-bottom: 98px;
    }
    .about-pp21{
        font-size: 20px;
    }

     */
.blog-h2 {
  font-size: 20px;
}
.blog-y {
  display: flex;
  justify-content: center;
}
.blo-logo {
  width: 200px;
}
.blo-kk {
  display: flex;
  justify-content: flex-end;
  padding: 0px 20px;
}
.intro-o {
  font-size: 15px;
  padding: 10px 20px;
}
.paf-g {
  width: 100%;

  padding-bottom: 50px;

  display: flex;
  justify-content: center;
}
.blo-int {
  display: flex;
  padding: 20px 20px;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
@media only screen and (max-width: 870px) {
  .blo-int {
    display: flex;
    display: flex;
    padding: 20px;
    gap: 2rem;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.react-tabs__tab--selected {
  /* background: unset !important;
  border-color: unset !important;
  color: white !important;
  border-radius: unsetx !important; */
}

.react-tabs__tab {

  bottom: -1px;

}

.tab-oi {
  width: 80%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-top: 20px;
}
.react-tabs {

}

.tab-1 {
  color: white;
  font-size: 13px;
  /* text-align: center; */
  /* padding: 15px 0px; */
  cursor: pointer;
  line-height: 1.5;
}
.tab-s-p {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 1rem;
  padding:10px 0px;
  /* text-align: center; */
  /* margin: 20px; */
  
}

/* .tab-1:focus {
  border: 2px solid white;
  padding: 5px;
  border-radius: 10px;
} */
.tab-1:hover {
}

@media only screen and (max-width: 1050px) {
  .blo-int {
    /* padding: 20px 0px; */
  }
  /* .tab-oi {
   width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
    } */
  /* .tab-panel-i {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    } */
}

@media only screen and (max-width: 930px) {
  .sidebar {
    /* display: none ; */
  }
  /* .tab-panel-i {
        display: flex;
        align-items: center;
        justify-content: center;
    } */
}
.sidebar {
  /* display: none ; */
  overflow-y: scroll;
}


.blo-int > p {
  font-size: 16px;
}
.hr-blog {
  border: 0.5px solid white;
}
.svg-open {
  padding: 10px;
  font-size: 30px;
}

@media only screen and (max-width: 1054px) {
  .i-frame-o {
    width: 100% !important;
  }
}
.iframe-w-v {
  width: 100%;
}
.i-frame-o {
  width: 60%;
}

.fle-main-div {
  display: flex;
}
/* Follow me for more pens like this! */

/* Tweak to change the look and feel */
:root {
  --dropdown-highlight: dodgerblue;
  --dropdown-width: 160px;
  --dropdown-background: white;
  --dropdown-color: black;
}
.menu-modules{
  /* position: fixed;
z-index: 10;
  left: 100px;
  top: 100px; */
  /* padding: 100px 0px 0px 0px; */
  position: fixed;
}
  
.head-pvt{
  /* position:fixed !important;
  z-index: 100 !important;  */
}
.tabs-1{
  padding: 100px 0px 0px 0px;

}
.accordion__heading{
  padding: 10px 0px;
}

.lessons-a1>h1{

  font-size: 20px;
  padding: 10px 0px;
  /* font-weight: 400; */
}
.span-1-lessons{
  color: #bc0000;
  cursor: pointer;
}

.lessons-a2>h1{

  font-size: 15px;
  padding: 10px 0px;
  font-weight: 400;
}
.lessons-a3>h1{

  font-size: 20px;
  padding: 10px 0px;
  /* font-weight: 400; */
}
.lessons-a4>h1{

  font-size: 15px;
  padding: 10px 0px;
  font-weight: 400;
}

.sidebar-a1{
  padding:10px;
}
.progress-bar-a1{
  padding: 10px 0px;
}
.head-h>img{

  width: 170px;
}
.head-h{

  display: flex;
}
.close-svg1{
  color: black;
    font-size: 20px;
  }
  .close-div-svg{
    display: flex;
    justify-content: flex-end;
    margin: 10px;
  }
  .side-mod1{
    font-size: 13px;
    padding: 4px 0px;
  }
  .side-mod2{
    font-size: 13px;
    padding: 4px 0px;
   
  }
  .side-mod3{
    padding: 10px 0px;
  }
  .side-m-h1{

    font-size: 15px;
  }
  .module-title-2{
    padding: 10px 0px;
  }
  .module-side-a1{
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    background: #a9a9a94a;
    align-items: center;
}
.module-side-h1{
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  /* text-decoration-line: line-through; */
  color: black;
  
  text-transform: uppercase;
}
.module-side-h1:hover{
color: #bc0000;
}
.module-side-a2{
  display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 10px 0px;
}
.head-side-modules{

  overflow-y: scroll;
  height: 100%;
}